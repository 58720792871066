$piterjs_app $mol_view
	Domain $piterjs_domain
		meetups => meetups
		meetup* => meetup*
		meetup_public*? => meetup_public*?
		editable => editable
	plugins /
		<= Theme $mol_theme_auto
	sub /
		<= Screen $piterjs_screen
			place <= place $piterjs_place
			Open null
			Close null
			content /
				<= Book $mol_book2
					pages <= pages /any
					Placeholder null
		<= Toggle_intro $mol_check
			checked?val <=> toggle_intro?val false
	Menu $mol_page
		title \Митапы
		tools <= tools /
			<= Meetup_add $mol_button_minor
				click? <=> meetup_add? null
				sub / <= Meetup_add_icon $mol_icon_plus
			<= Safe_link $mol_link
				arg * safe \
				sub /
					<= Safe_icon $mol_icon_key_variant
		foot <= foot /
			<= Online $hyoo_sync_online
				yard <= Yard $hyoo_sync_client
			<= Editing $mol_check_icon
				Icon <= Editing_icon $mol_icon_settings_outline
				checked? <=> editing? false
			<= Rights_toggle $mol_check_icon
				checked? <=> rights? false
				Icon <= Rights_toggle_icon $mol_icon_shield_account
			<= User $mol_view sub / <= user_id \0_0
			<= Lights $mol_lights_toggle
		body /
			<= Menu_content $mol_view sub /
				<= Links $mol_list rows /
					<= Wiki_link $mol_link
						arg *
							wiki \
							meetup null
						title \База знаний
					- <= Others_link $mol_link
						uri \#others
						title \Другие события
				<= Meetups $mol_list rows <= menu_meetups /
				<= Conf $mol_link
					uri \https://conf.piterjs.org/
					sub /
						<= Conf_title $mol_view
							sub /
								\Conf 
								<= Conf_ext $mol_icon_share_outline
						<= Conf_date $mol_view
							sub / \сентябрь'19
	Meetup* $piterjs_meetup_page
		meetup <= meetup*
		editing? <=> editing? false
		meetup_public? <=> meetup_public*?
		rights? <=> rights_meetup? false
	Meetup_guests* $piterjs_meetup_guests
		meetup <= meetup*
	Meetup_stats* $piterjs_meetup_stats
		meetup <= meetup*
		meetup_prev <= meetup_prev* $piterjs_meetup
	Speech!id $piterjs_speech_page
		speech <= speech!id $piterjs_speech
		editing? <=> editing? false
		speech_public? <=> speech_public*? false
	Menu_meetup* $piterjs_meetup_snippet
		meetup <= meetup*
	Now $piterjs_now
		place <= place $piterjs_place
	Intro $piterjs_intro
		meetup <= meetup_current $piterjs_meetup
		page?val <=> intro?val \
	Video $piterjs_video_page
		source? <=> video_uri? \
		editing <= editing? false
	Place $piterjs_place_page
		place <= place $piterjs_place
		editing <= editing? false
	Others $piterjs_others
	Rights $hyoo_meta_rights
		theme \$mol_theme_special
		meta <= Domain
		tools /
			<= Rights_close $mol_check
				checked? <=> rights?
				sub /
					<= Rights_close_icon $mol_icon_close
	Rights_meetup $hyoo_meta_rights
		theme \$mol_theme_special
		meta <= meetup_current
		tools /
			<= Rights_meetup_close $mol_check
				checked? <=> rights_meetup?
				sub /
					<= Rights_meetup_close_icon $mol_icon_close
	Wiki $hyoo_page
		side_main_id \zi5t5h_8aygdf
		yard <= Yard
	Safe $hyoo_meta_safe
		tools /
			<= Safe_close $mol_link
				arg * safe null
				sub /
					<= Safe_close_icon $mol_icon_close
