$piterjs_intro $mol_view
	title \PiterJS Вступление
	meetup $piterjs_meetup
	page?val \
	pages *$piterjs_intro_page
		main <= Main $piterjs_intro_main meetup <= meetup
		about <= About $piterjs_intro_page
			title \Кто мы?
			text \
				\Митапы в Питере
				\JS и всё, что рядом
				\Каждый месяц с 2015
				\Спячка с 2020
				\С 2023 снова с вами!
		- projects <= Projects $piterjs_intro_page
			title \Наши проекты
			text \
				\PiterJS **Meetups** - митапы в Петербурге
				\PiterJS **Tour** - митапы в других городах
				\PiterJS **Conf** - конференции
				\PiterJS **Code+Learn** - воркшопы
		team <= Team $piterjs_intro_page
			title \Команда
			text \
				\! ""https://i.imgur.com/wXGooly.jpeg""
				\  ! ""https://i.imgur.com/dRolfnB.jpeg""
				\    ! ""https://i.imgur.com/Mup6yZ5.jpeg""
				\      ! ""https://i.imgur.com/V2Rkj38.jpeg""
				\! ""https://i.imgur.com/WYQiVs6.jpeg""
				\  ! ""https://i.imgur.com/nQD3DUQ.jpeg""
				\    ! ""https://i.imgur.com/n2Fxkqv.png""
				\      ! ""https://i.imgur.com/L9H07ur.jpeg""
		roles_org <= Roles_org $piterjs_intro_page
			title \Роли организаторов
			text \
				\Программный комитет
				\Фандрайзер
				\Видео-мастер
				\Дизайнер
				\Комьюнити-менеджер
				\Координатор
		roles_place <= Roles_place $piterjs_intro_page
			title \Роли на площадке
			text \
				\Конферансье
				\Техник
				\Видео-оператор
				\Фотограф
				\Ведущий трансляции
				\Хелпер
				\Координатор
		- friends <= Friends $piterjs_intro_page
			title \JUG.RU
			text \
				\Продюссер
				\Инженер
				\Монтажёр
				\Режиссёр трансляции
				\Ведущий мероприятия
				\Ведущий воркшопа
				\Подготовим
		contribution <= Contribution $piterjs_intro_page
			title \Твой вклад
			text \
				\Привлекай компанию
				\Поддерживай рублём
				\Вступай в наши ряды
				\Выступай сам
		speakers <= Speakers $piterjs_intro_page
			title \Докладчики
			text \
				\Нужны всегда
				\Даже если боишься
				\Поможем с темой
		sponsors <= Sponsors $piterjs_intro_page
			title \Спонсоры
			text \
				\! ""/piterjs/logo-sponsors/kvant-logo.png""
				\  ! ""/piterjs/logo-sponsors/Nexign_Logo.png""
				\    ! ""/piterjs/logo-sponsors/sprinthost.ru-2.png""
		place <= Place $piterjs_intro_page
			title <= place_title \Мы в {place}
			text <= place_notes \
		schedule <= Schedule $piterjs_schedule
			meetup <= meetup
			title \Сегодня
		profit <= Proft $piterjs_intro_page
			title \Бонусы
			text \
				\За лучшие вопросы - призы
				\Мы ведём трансляцию
				\И записываем видео
				\Улыбайтесь фотографу
		- follow <= Follow $piterjs_intro_page
			title \Следите за новостями
			text \
				\vk.com/piterjs
				\t.me/piterjs
				\youtube.com/piterjs
				\piterjs.org
		afterparty <= Afterparty $piterjs_intro_page
			title \Го в бар!
			text <= afterparty \
	sub /
		<= Screen $piterjs_screen
			place <= place $piterjs_place
			content /
				<= Page $mol_view
	attr *
		tabindex -1
	plugins /
		<= Nav $mol_nav
			keys_x <= page_ids /string
			keys_y <= page_ids /string
			current_x?val <=> page?val
			current_y?val <=> page?val
